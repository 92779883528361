import A from "axios";
import { v4 as uuidv4 } from "uuid";
import md5 from "crypto-js/md5";
import envConfig from "../utils/envConfig";
import { Loading } from "element-ui";
import { Toast } from 'vant';
import router from '@/router'

var loading = null;

const axios = A.create({
  // baseURL: "/api",
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    clientType: "WAP",
    terminal: "WAP",
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwtToken");
    console.log(token)
    if (token && token !== "null" && token !== "undefined") {
      config.headers["Authorization"] = token;
      console.log('13123')
    }
    loading = Loading.service({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    loading.close();
    return response;
  },
  (error) => {
    if (error.response.status === 401) {//未登录
      router.push('/login')
    }
    //Toast("系统错误,请稍候再试", 500, 5000);
    return {};
  }
);

const showTips = (data) => {
  if (data?.msg) {
    Toast(data?.msg, data?.code, 5000);
  }
};
//=======用户中心接口=============
export const checkUserExist = async (reqbody) => {//校验手机号是否注册
  const body = reqbody;
  const { data } = await axios.post("/api/user/checkUserExist", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};
export const getPublicKey = async (reqbody) => {//初始化获取key
  const body = reqbody;
  const { data } = await axios.post("/api/user/getPublicKey", body);
  // if (+data?.code === 1) {
    const res = data;
    return res;
  //}
  //showTips(data);
};

export const captcha = async (reqbody) => {//获取图形验证码
  const body = reqbody;
  const {data } = await axios.post("/api/user/captcha", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const sendSmsBeforLogin = async (reqbody) => {//发送短信验证码（登录前）
  const body = reqbody;
  const { data } = await axios.post("/api/user/sendSmsBeforLogin", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const login = async (reqbody) => {//登录
  const body = reqbody;
  const {data } = await axios.post("/api/user/login", body);
  if (+data?.code === 1) {
    const res = data;
    if (res?.jwtToken) {
      localStorage.setItem("jwtToken", res?.jwtToken);
    }
    return res;
  }
  showTips(data);
};
export const register = async (reqbody) => {//注册
  const body = reqbody;
  const { data } = await axios.post("/api/user/register", body);
  if (+data?.code === 1) {
    const res = data;
    if (res?.jwtToken) {
      localStorage.setItem("jwtToken", res?.jwtToken);
    }
    return res;
  }
  showTips(data);
};
export const logout = async (reqbody) => {//退出
  const body = reqbody;
  const { data } = await axios.post("/api/user/logout", body);
  if (+data?.code === 1) {
    const res = data;
    localStorage.removeItem("jwtToken");
    return res;
  }
  showTips(data);
};

export const signProcotol = async (reqbody) => {//协议
  const body = reqbody;
  const { data } = await axios.post("/api/user/signProcotol", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const newProcol = async (reqbody) => {//协议
  const body = reqbody;
  const {data } = await axios.post("/api/user/newProcol", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const setLoginPwd = async (reqbody) => {
  const body = reqbody;
  const {data } = await axios.post("/api/user/setLoginPwd", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const queryUserIsRealName = async (reqbody) => {//实名绑卡
  const body = reqbody;
  const { data } = await axios.post("/api/user/auth/queryUserIsRealName", body);
  if (+data?.res_code === 1) {
    const res = data;
    return res;
  }else if(+data?.code ==401){
    toLogin();
  }
  showTips(data);
};

export const getUserBaseInfo = async (reqbody) => {//获取个人还是企业
  const body = reqbody;
  const { data } = await axios.post("/api/user/getUserBaseInfo", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const preFourFactorAuth = async (reqbody) => {//实名绑卡发送验证码
  const body = reqbody;
  const {data } = await axios.post("/api/user/auth/preFourFactorAuth", body);
  if ( +data?.res_code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const confirmFourFactorAuth = async (reqbody) => {//实名绑卡提交
  const body = reqbody;
  const { data } = await axios.post("/api/user/auth/confirmFourFactorAuth", body);
  if ( +data?.res_code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const realNameVerified = async (reqbody) => {//二要素校验
  const body = reqbody;
  const { data } = await axios.post("/api/user/auth/realNameVerified", body);
  if (+data?.res_code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const procotolist = async (reqbody) => {//隐私协议
  const body = reqbody;
  const { data } = await axios.post("/api/user/procotolist", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const procotolDetail = async (reqbody) => {//隐私协议详情
  const body = reqbody;
  const { data } = await axios.post("/api/user/procotolDetail", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const queryUserBankCard = async (reqbody) => {//银行卡
  const body = reqbody;
  const { data } = await axios.post("/api/user/auth/queryUserBankCard", body);
  if (+data?.res_code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const captcha1 = async (reqbody) => {//获取图形验证码-理财经理
  const body = reqbody;
  const {data } = await axios.post("/api/crm/login/captcha", body);
  if (+data?.code === 1) {
    const res = data;
    console.log("/api/crm/login/captcha===>res", res);
    return res;
  }
  showTips(data);
};

export const sendSmsBeforLogin1 = async (reqbody) => {//发送短信验证码-理财经理
  const body = reqbody;
  const {data } = await axios.post("/api/crm/login/sendSmsBeforLogin", body);
  if (+data?.code === 1) {
    const res = data;
    console.log("/api/crm/login/sendSmsBeforLogin===>res", res);
    return res;
  }
  showTips(data);
};

export const login1 = async (reqbody) => {//获取图形验证码-理财经理
  const body = reqbody;
  const {data } = await axios.post("/api/crm/login/doLogin", body);
  if (+data?.code === 1) {
    const res = data;
    console.log("/api/crm/login/doLogin===>res", res);
    return res;
  }
  showTips(data);
};

export const checkPicCode = async (reqbody) => {//获取图形验证码-理财经理
  const body = reqbody;
  const {data } = await axios.post("/api/crm/login/checkPicCode", body);
  if (+data?.code === 1) {
    const res = data;
    console.log("/api/crm/login/checkPicCode===>res", res);
    return res;
  }
  showTips(data);
};

export const getUserManager = async (reqbody) => {//获取用户信息-理财经理
  const body = reqbody;
  const {data } = await axios.post("/api/crm/getUserManager", body);
  if (+data?.code === 1) {
    const res = data;
    console.log("/api/crm/getUserManager===>res", res);
    return res;
  }
  showTips(data);
};

export const getUserInviteListPage = async (reqbody) => {//获取用户信息-理财经理
  const body = reqbody;
  const {data } = await axios.post("/api/crm/getUserInviteListPage", body);
  if (+data?.code === 1) {
    const res = data;
    console.log("/api/crm/getUserInviteListPage===>res", res);
    return res;
  }
  showTips(data);
};
 
export const getWxConfig = async (reqbody) => {//获取用户信息-理财经理
  const body = reqbody;
  const {data } = await axios.post("/api/crm/wx/getWxConfig", body);
  if (+data?.code === 1) {
    const res = data;
    console.log("/api/crm/wx/getWxConfig===>res", res);
    return res;
  }
  showTips(data);
};