export const config = {};
config.production = {
  //apiHost: '',
  //appName: 'user_center-production',
  RSAPublicKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvGqEg3ioYL9l9RNC337hjh20z8GYIJ/pS/kfnqhGaceyt7eALcOQQj1URSCJJslcAE68aLsYScYeHQJluB0B8JYqAgohqHM1jxJzDBBt2/D4LQWwo4iODKFjYvSG3gOT9ICnf9PTssMhnYk7eX1otJ+iF2UvjM+aFiF30jF7t9tXwT+bbyiN8wCU84XY5UDD+8pOmm0w4F7gLvt15j+BI+YUitQeZlEg2gPGokxR/YM8NqgSJJyzyZW25YQ5HCDvO6DBy9/iCJ5KbuhViuEFgb7KGWGqjQd1pvIMnJVbIaza0I132eKhAs3Gmd1DIB0KWXv99zoo5OzkRTu7xHz+IQIDAQAB',
  RSAPrivateKey: 'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC8aoSDeKhgv2X1E0LffuGOHbTPwZggn+lL+R+eqEZpx7K3t4Atw5BCPVRFIIkmyVwATrxouxhJxh4dAmW4HQHwlioCCiGoczWPEnMMEG3b8PgtBbCjiI4MoWNi9IbeA5P0gKd/09OywyGdiTt5fWi0n6IXZS+Mz5oWIXfSMXu321fBP5tvKI3zAJTzhdjlQMP7yk6abTDgXuAu+3XmP4Ej5hSK1B5mUSDaA8aiTFH9gzw2qBIknLPJlbblhDkcIO87oMHL3+IInkpu6FWK4QWBvsoZYaqNB3Wm8gyclVshrNrQjXfZ4qECzcaZ3UMgHQpZe/33Oijk7ORFO7vEfP4hAgMBAAECggEANTN6cUj7ej3xAw9KPaMs+xzmNhd65hNhI4OXawwmva2WiAt3VPS4YgvBJLe4IkyO/sM8rfHOPN6FGDnaEO+X4Z/1Aphrwo2tXNWfyJNHAeh7mvLVWoevXF+hmYpkHoIyH8LICO7+AqbYcgwS0A4aJ44/jY2TrJXd80vF8oLoZRQ8nUqC1Hw/N5aUdd/EFHSElSQUQ8NIwgJIW2m/Ctu/qIRqBtgeYScvPiyWH5XqOw0EpRdeYmUn0pG+7m7j46ULxTkE3Gd8FZlqYESeK6XnJvOm6r12GND3uCaOX6x0xNPGrCoucg7RyiJfl16y6RGYOB5KlFMN1sQFvxTAg9SoAQKBgQDwZnZBclMbAfdqhQ+CqxLnfqhM9hxKe6PHtnoe4MhxsQFQcvTh0KhCoII/qCmQNHzs4PBfIq60PMMql5n78rJ6IftrpffDNyHktN71dBQDA57ZmI0IMynJBfmmqEGXvW5CGz0+T6cPzB9SB33IyIGuelI+mL+UTBpu0WIveVEvwQKBgQDIpH4s43YC/AXb1W0jRfGV6XVhAKMfZlXFA4lX9CXON6QjruyuKKcpNt2zIhaOzpw04e0NAGoPNs7Y8Vp/h8t1VOZz1Iee67/9ufCbMtBYtXM1buHuVoDanJhMooPC7l7WPj7Ml9d+VKhfRVOsbMQcMoUx8skSp+AiNhnwAHNmYQKBgEwwDNZdBx2OF/14QhwlX45iQ7BwZzLr0FIg8L8Mejod4M7AP/+5AzpqaB0IjHOZTuY7u7JK6ZdRkoDTHwgMOKggUPAi30zNEj7LW8XqjdUOtq87e6bXoCyHakF5HaCbdnAY9eXjlIybImn62dBiS0cimSIhgsXbAJ2SWk21YxeBAoGBAKBnsV/dsTkNlvl7FTd/8XXyIJ8eTUsLG0LYYvLoA1lr7lOAw0MwyQMQFAnkFLiywPseK8+bJy9ml9OkkL8oJyZ0a5Dqtkn9G46TGqJY62wgFC0BqqpKidU8IB4URtaOj08kkDwsoGEk+T7BQ/WzNs18LjQSb2H0yCFRs0bGdXvBAoGBANxuW71CaJk/EkkbleQ+VZn3OJn9zDuZhQznlhXe8F3oNOPi5w+XDIjap22zyoN8MJKl6RRIeQXeAWdjZSiycIbEoIczIgSHnCHlGU3ZCSZpRY4PQJKNDSJu4C7VnCIgs3m7tzPrlPfUpfXk5XeeNk/n1puPKgWHlA46A/L1sZx6'
};

config.test = {
  //apiHost: '',
  //appName: 'user_center-test',
  RSAPublicKey: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCRqL/ke4gle36pZNZf5h+g0ZOZSoCaeyENgS6OUUptGTO8qfaqwpmqiI/5rpyrH+Lj5EdIzm9ABbxgiDuAPjuyBkmgDzgEG9IQQ3nv1Jo3WWpRwGngv2yXmkq6lHf+5ZEAickg2JPE4xvD7iAHViBmEE6BgNgxTmpflcp7Gdl1YwIDAQAB',
  RSAPrivateKey: 'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAJGov+R7iCV7fqlk1l/mH6DRk5lKgJp7IQ2BLo5RSm0ZM7yp9qrCmaqIj/munKsf4uPkR0jOb0AFvGCIO4A+O7IGSaAPOAQb0hBDee/UmjdZalHAaeC/bJeaSrqUd/7lkQCJySDYk8TjG8PuIAdWIGYQToGA2DFOal+VynsZ2XVjAgMBAAECgYAvAcSb2foADrC7vh0o6b0/SyNVDyQdtqreDtiVeUqJlt6Jflhql068KEI+k8lfuHSikyMmLejdQnyesphxNKRSupi7JfcMpabTd4nbLbjeO32Dk0CbtvS9E0uEPDsAasvjQlb+p/ZAXy0u0XpFXgAuPbAblukxB49xCo4mx8HCQQJBAOo8al2cYTfpkJlmMdKoZcaJlAjwZll6PrnBfSkWumnaycWtW0kxT84vl1WNEZ73f6T8aLjO8htpK7iPQINs1vECQQCfMW2U60X4qS5ejmVKTUb0RRg+1/l+5lC+J0zFOafDSo9jkFJFFELnx6slymLRW7vpV/CPPA9tfFFAtkMcJJmTAkBaDZgnHTQTNuyszSlif/OA/Ou49BOlWuY6rDTeUyqf/8mLAjcSUK8J6ioYbf/USfr9Bz2Lr6RM/YYdkpWO8Y1BAkEAiC4hXtZRGiGn4A2SCpjGzOlaj0/0R6FAixpVaxYiNEbRGMv6BMwLW/mLqB1UoWIHAttOl0k6rV8+pswD5sxMbwJAZzfXCmUymI7i4sv9WWARg75YysnVmeW/jUMH2GopGprl1+Jmfc86LLaKIfqJfVwqIqPeES+nxvEb2LLahvfghw=='
};

config.development = {
  //apiHost: '',
  apiProxyHost: config.test.apiHost,
  //appName: config.test.appName,
  RSAPublicKey: config.test.RSAPublicKey,
  RSAPrivateKey: config.test.RSAPrivateKey
};

const appEnv = process.env.VUE_APP_MODE;
const envConfig = config[appEnv] || config.production;

export default envConfig;
