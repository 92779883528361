<template>
  <div class="accountInfo">
    <div class="regular" style="top: 0px;">
      <div class="group group-no-padded">
        <div class="group-body">
          <ul class="list">
            <li class="item">
              <span style="padding-left: 5px;">用户名</span>
              <span style="padding-right: 5px;">{{mobilePhone}}</span>       
            </li>
          </ul>
          </div>
        </div>
      <div class="group group-no-padded">
        <div class="group-body">
          <ul class="list">
            <li class="item"><h3 class="item-title">绑定手机号</h3><div class="item-after">{{mobilePhone}}</div></li>
            <router-link :to="{path:'privacySettings'}">
               <li class="item"><h3 class="item-title">隐私协议</h3><i class="el-icon-arrow-right"></i></li>
            </router-link>
            <!-- <li class="item"><h3 class="item-title">联系我们</h3><div class="item-after">400-178-7888</div></li> -->
          </ul>
        </div>
      </div>
      <div>
        <a class="button" @click="loginOut()">退出登录</a>
      </div>
    </div>
  </div>
</template>
<script>
import { logout} from "../services/api";
export default {
  data() {
    return {
      mobilePhone:'',
    };
  },
  created: function() {
    const mobilePhone = localStorage.getItem("mobilePhone")
    
    this.mobilePhone=mobilePhone.replace(/^(.{3})(?:\d+)(.{4})$/,"$1****$2")
  },
  methods: {
    async loginOut() {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("mobilePhone");
      localStorage.removeItem("userId");
       localStorage.removeItem("jroiqohoi");
      this.$router.replace({
        path: "login",
        query: { }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
.accountInfo{
  margin-top: 2.25rem;    margin-bottom: 2.25rem;
  .regular {
    position: relative;
    top:0;  
    .group {
      margin: 1rem 0; background: #fff;
      ul{
        .item {
              padding: 1rem;
              margin-bottom: -1px;
              border: 1px solid #e6e6e6;
              border-width: 1px 0;
              min-height: 1rem;
              display: flex;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              -webkit-box-align: center;
              align-items: center;

              h3{font-size:0.8rem;font-weight: normal;}
              .item-after {
                  color: #888;
                  margin-left: 0.3125rem;
              }
          }
        
      }
    }
  }
  .button{margin-top:5rem;}
}
</style>
